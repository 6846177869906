import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

const cardNumberRegex = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$/,
  amex: /^3[47][0-9]{13}$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
};

const getCardType = (number) => {
  if (cardNumberRegex.visa.test(number)) {
    return 'Visa';
  } else if (cardNumberRegex.mastercard.test(number)) {
    return 'MasterCard';
  } else if (cardNumberRegex.amex.test(number)) {
    return 'American Express';
  } else if (cardNumberRegex.discover.test(number)) {
    return 'Discover';
  } else {
    return 'Unknown';
  }
};

const Step3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, currency } = location.state || { amount: 0, currency: 'USD' };

  const [cardType, setCardType] = useState('');
  const [showCVV, setShowCVV] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      cardholder: '',
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string()
        .required('It is necessary to specify the card number')
        .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Invalid card number'),
      expiryDate: Yup.string()
        .required('The date of expiry must be specified')
        .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Invalid expiration date')
        .test('expiryDate', 'The card cannot be past expiration date', (value) => {
          if (!value) return false;
          const [month, year] = value.split('/');
          const currentDate = new Date();
          const selectedDate = new Date(`20${year}`, month - 1);
          return selectedDate >= currentDate;
        }),
      cvv: Yup.string()
        .required('CVV should be specified')
        .matches(/^[0-9]{3,4}$/, 'Invalid CVV code'),
      cardholder: Yup.string()
        .required('It is necessary to specify the name of the cardholder')
        .matches(/^[a-zA-Z ]+$/, 'Invalid cardholder name'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      
      try {
        const response = await fetch('https://script.google.com/macros/s/AKfycbzILTTYbhIGWjtj1b0Paaodd6Cui519a0sL2YAgozgjE0TaqMWIVygGwJBTiIZDsOkQ7Q/exec', {
          method: 'POST',
          mode: 'no-cors',
          body: JSON.stringify({
            cardholder: values.cardholder,
            amount,
            currency,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(() => {
          navigate('/success');
        }).catch(error => {
          console.error('Error sending data to Google Sheets:', error);
        });
        
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log('Success:', data);
        navigate('/success');
      } catch (error) {
        console.error('Error sending data to Google Sheets:', error);
      } finally {
        setLoading(false);
      }
    },
    
  });

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    setCardType(getCardType(value));
    formik.handleChange(e);
  };

  return (
    <div className='container'> 
      <div className='wrapper_step3'>
      <h1 className='text-center'>Enter your card details to proceed</h1>
      <p>Amount: {amount} {currency}</p>
      <form onSubmit={formik.handleSubmit}>
        <div className='input_wrapper'>
          <label htmlFor="cardNumber">Card Number</label>
          <InputMask
            mask="9999 9999 9999 9999"
            maskChar="-"
            id="cardNumber"
            name="cardNumber"
            type="text"
            placeholder="---- ---- ---- ----"
            onChange={handleCardNumberChange}
            value={formik.values.cardNumber}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber ? (
            <div className='errors'>{formik.errors.cardNumber}</div>
          ) : null}
          {/* <div>Type: {cardType}</div> */}
        </div>
        <div className='input_wrapper2'>
          <div className='input_wrapper'>
            <label htmlFor="expiryDate">Expiry Date</label>
            <InputMask
              mask="99/99"
              maskChar=" "
              id="expiryDate"
              name="expiryDate"
              type="text"
              placeholder="MM/YY"
              onChange={formik.handleChange}
              value={formik.values.expiryDate}
            />
            {formik.touched.expiryDate && formik.errors.expiryDate ? (
              <div className='errors'>{formik.errors.expiryDate}</div>
            ) : null}
            </div>
          <div className='input_wrapper cvv'>
          <label htmlFor="cvv">CVV</label>
          <div style={{ position: 'relative' }}>
            <InputMask
              mask="999"
              maskChar=" "
              id="cvv"
              name="cvv"
              type={showCVV ? 'text' : 'password'}
              placeholder="•••"
              onChange={formik.handleChange}
              value={formik.values.cvv}
            />
            <span className="eye-icon" onClick={() => setShowCVV(!showCVV)}>
              {showCVV ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>
          {formik.touched.cvv && formik.errors.cvv ? (
            <div className='errors'>{formik.errors.cvv}</div>
          ) : null}
        </div>
        </div>
       
        <div className='input_wrapper'>
          <label htmlFor="cardholder">Card Holder</label>
          <input
            id="cardholder"
            name="cardholder"
            type="text"
            placeholder="Card Holder"
            onChange={formik.handleChange}
            value={formik.values.cardholder}
          />
          {formik.touched.cardholder && formik.errors.cardholder ? (
            <div className='errors'>{formik.errors.cardholder}</div>
          ) : null}
        </div>
        <div className='button_wrapper'>
          <button type="submit" className='button' disabled={loading}>
            {loading ? 'Loading...' : 'Confirm'}
          </button>
          <button  type="button" className='button_cancel' onClick={() => navigate('/')}>Cancel</button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Step3;
