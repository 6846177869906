import React from 'react'

export const Header = () => {
  return (
    <header>
      <div className='container'>
        <h1 className='logo '>Truttay</h1>
      </div>
    </header>
  )
}
